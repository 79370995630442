import { single } from 'fuzzysort'
import isNil from 'lodash.isnil'
import { type SvgProps } from 'react-native-svg'
import type { ProductVariantDbPublicComposite } from '@centrito/api/nest/platform/database/domain'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import localStorageClient from '@centrito/app/utils/services/localStorage'
import { OUTLETS_LOGOS } from '@centrito/ui/src/components/svgs/outlets/logos'

// Original: https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula

const GENDER_ORDER = ['Moda mujer', 'Moda Hombre', 'Niña', 'Niño', 'Bebé', 'Accesorios', 'Calzado']
const BEST_SELLING_ORDER = ['Bodys', 'Jeans', 'Chaquetas', 'Blusas']

// Takes a number like +571234567890 and returns 123-456-7890
export const formatPhoneNumberForForm = (unformattedPhoneNumber: string): string => {
  const unformattedWithoutCountryCode = unformattedPhoneNumber
    .replace('+57', '')
    .replace('+58', '')
    .replace(/-/g, '')
  const formattedPhoneNumber =
    unformattedWithoutCountryCode?.slice(0, 3) +
    '-' +
    unformattedWithoutCountryCode?.slice(3, 6) +
    '-' +
    unformattedWithoutCountryCode?.slice(6)
  return formattedPhoneNumber
}

export const compareCategories = (a: CategoryPublic, b: CategoryPublic): number => {
  const aProcessed = BEST_SELLING_ORDER.indexOf(a.names[1].split(' ')[0])
  const bProcessed = BEST_SELLING_ORDER.indexOf(b.names[1].split(' ')[0])
  // 1. compare by gender
  if (GENDER_ORDER.indexOf(a.names[0]) < GENDER_ORDER.indexOf(b.names[0])) {
    return -1
  }
  if (GENDER_ORDER.indexOf(a.names[0]) > GENDER_ORDER.indexOf(b.names[0])) {
    return 1
  }
  // 2. One of the categories doesnt exist on bestsellingOrder
  if (aProcessed < 0 && bProcessed < 0) {
    return 0
  }
  if (aProcessed < 0) {
    return 1
  }
  if (bProcessed < 0) {
    return -1
  }
  // 3. compare by bestSelling
  if (aProcessed < bProcessed) {
    return -1
  }
  if (aProcessed > bProcessed) {
    return 1
  }
  return 0
}

export const isEmailValid = (email: string | undefined): boolean => {
  if (!email) return false
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    ? true
    : false
}

export const getSearchVariant = (
  searchTerm: string,
  listOfVariants: ProductVariantDbPublicComposite[],
): number => {
  // 1. preprocess
  const preprocess = listOfVariants.map((productVariant, variantIndex) => {
    const variantColor =
      productVariant.variant.colorData.primary?.map((color) => color.key).join(' ') || ''
    const splitted: string[] = variantColor.split(/[ -]/gm)
    return splitted.map((variantColor) => ({
      variantIndex,
      variantColor,
    }))
  })

  // 2. flatten
  const flatPreprocess = preprocess.flat()

  // 3. get scores
  const fuzzyScores = flatPreprocess.map(({ variantColor, variantIndex }) => {
    const result = single(searchTerm, variantColor)
    return { variantIndex, score: result ? result.score : -Infinity }
  })

  // get max score
  const maxScore = fuzzyScores.reduce(
    (prev, curr) => {
      return curr.score > prev.score ? curr : prev
    },
    { variantIndex: 0, score: -Infinity },
  )

  return maxScore.variantIndex
}

export const queryParamsToQueryString = (queryParams: object, prefix = '?'): string => {
  const queryStrings = Object.entries(queryParams)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
  return queryStrings.length > 0 ? `${prefix}${queryStrings}` : ''
}

export const DOWNLOAD_DIALOG_KEY = 'download_dialog'
export const DOWNLOAD_HEADER_KEY = 'download_header'

export const setLocalStorageExpiration = async (
  itemKey: string,
  duration: number,
): Promise<void> => {
  const now = new Date().getTime()
  const expiresAt = now + duration
  await localStorageClient.setItem(`${itemKey}_expiration`, JSON.stringify({ expiresAt }))
}

export const hasLocalStorageItemExpired = async (itemKey: string): Promise<boolean> => {
  const expirationRecord = await localStorageClient.getItem(`${itemKey}_expiration`)

  if (isNil(expirationRecord)) {
    return true
  }

  const { expiresAt } = JSON.parse(expirationRecord)
  const now = new Date().getTime()

  if (now > expiresAt) {
    return true
  }

  return false
}

export const isIosBrowser = (): boolean => {
  return !isNil(navigator) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
}

export const OUTLETS: {
  id: string
  name: string
  logo: React.FC<SvgProps>
  isActive: boolean
  bannerUrls: string[]
}[] = [
  {
    id: 'f351c47f-32a7-4aea-bb9e-f4695907454d',
    name: 'AMERICAN EAGLE',
    logo: OUTLETS_LOGOS['AMERICAN EAGLE'],
    bannerUrls: [],
    isActive: true,
  },
  {
    id: '26509c85-6bc8-4cd5-94f9-3be9c5831df8',
    name: 'AMERICANINO',
    logo: OUTLETS_LOGOS.AMERICANINO,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: 'd471242b-5f31-408c-a260-21130d4f40e9',
    name: 'AVEMARÍA',
    logo: OUTLETS_LOGOS.AVEMARÍA,
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/601d90a5-4951-43ea-af3b-917a04fe4f08.png',
    ],
    isActive: true,
  },
  {
    id: 'ccb7f4d8-7efa-4401-ae83-4540c4b04574',
    name: 'BABY FRESH',
    logo: OUTLETS_LOGOS['BABY FRESH'],
    bannerUrls: [],
    isActive: true,
  },
  {
    id: '6c2a2533-b6c1-4df0-ba47-c3e5f2bee0c1',
    name: 'BRISSA',
    logo: OUTLETS_LOGOS.BRISSA,
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/231e83af-3116-41b3-aa1c-d2944bbf236f.png',
    ],
    isActive: true,
  },
  {
    id: 'fb7f4c99-218f-4f54-b176-e95de3cf0b99',
    name: 'CAROL',
    logo: OUTLETS_LOGOS.CAROL,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: '1fd0163e-558d-495b-8c86-1e0e8210e332',
    name: "CARTER'S",
    logo: OUTLETS_LOGOS["CARTER'S"],
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/954b417b-cbfc-4124-a32c-b3554d3af28d.png',
    ],
    isActive: true,
  },
  {
    id: '1849915d-8f46-4ef7-b335-e6477fa21e15',
    name: 'CHEVIGNON',
    logo: OUTLETS_LOGOS.CHEVIGNON,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: 'aa714351-0b24-4ec2-b614-5ee85ffcb125',
    name: 'CROMANTIC',
    logo: OUTLETS_LOGOS.CROMANTIC,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: '0caf1c17-22c4-418e-b704-3455d4dad88b',
    name: 'ESPRIT',
    logo: OUTLETS_LOGOS.ESPRIT,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: '28b82b52-6431-40fc-b003-900b1b4a2d21',
    name: 'FLORIPONDIA',
    logo: OUTLETS_LOGOS.FLORIPONDIA,
    bannerUrls: [],
    isActive: false,
  },
  {
    id: '9fee2bfe-b787-41be-a80a-8537cf9f174b',
    name: 'GEF',
    logo: OUTLETS_LOGOS.GEF,
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/9160d8bc-baa4-4f18-807e-686f28464c9b.png',
    ],
    isActive: true,
  },
  {
    id: '5af3d9a4-8a2a-457c-9ceb-55e4e1ae198c',
    name: 'KOAJ',
    logo: OUTLETS_LOGOS.KOAJ,
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/72136f29-692a-45d4-9d5c-0920bdbc8a67.png',
    ],
    isActive: true,
  },
  {
    id: 'e1d7f42b-c5bc-4e1a-a98f-daba16109dd2',
    name: 'LILI PINK',
    logo: OUTLETS_LOGOS['LILI PINK'],
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/9f3cef4e-6709-4f8a-8821-98d0ef3fac75.png',
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/1b594f31-78df-46d6-8db3-1e945b55ab15.png',
    ],
    isActive: true,
  },
  {
    id: 'fdedfb8e-3c1d-4b8e-85f0-6ce85be49f6a',
    name: 'MIC',
    logo: OUTLETS_LOGOS.MIC,
    bannerUrls: [],
    isActive: false,
  },
  {
    id: '20470b64-6382-40c9-9706-935b02433c76',
    name: 'MOVIES SHOP',
    logo: OUTLETS_LOGOS.MOVIES,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: 'df07f0ee-3605-465b-a344-4b6f3078a0be',
    name: 'OFFCORSS',
    logo: OUTLETS_LOGOS.OFFCORSS,
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/f02bebc9-206e-4cf8-972d-9dde06bd4973.png',
    ],
    isActive: true,
  },
  {
    id: '4240a155-6ffc-435c-812b-35a70d6756ec',
    name: 'PUNTO BLANCO',
    logo: OUTLETS_LOGOS['PUNTO BLANCO'],
    bannerUrls: [],
    isActive: true,
  },
  {
    id: 'ef9a1ec1-a6bd-486e-bfe4-a078d9fb5ee0',
    name: 'RIFLE',
    logo: OUTLETS_LOGOS.RIFLE,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: '45d24fb9-ac50-467a-95ea-03d4224128b6',
    name: 'SEVEN SEVEN',
    logo: OUTLETS_LOGOS['SEVEN SEVEN'],
    bannerUrls: [],
    isActive: true,
  },
  {
    id: '75440ea7-272f-4096-ba8b-5cfcfe8acda4',
    name: 'TENNIS',
    logo: OUTLETS_LOGOS.TENNIS,
    bannerUrls: [],
    isActive: true,
  },
  {
    id: 'a7915c53-99cf-4ecf-90a6-5c53b79daa44',
    name: 'THM',
    logo: OUTLETS_LOGOS.THM,
    bannerUrls: [],
    isActive: false,
  },
  {
    id: 'd52632af-e1fd-4bbd-95a7-e46d317e1f70',
    name: 'TOTTO',
    logo: OUTLETS_LOGOS.TOTTO,
    bannerUrls: [
      'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/Totto_Banner.jpg',
    ],
    isActive: true,
  },
]
