import React from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'

const PuntoBlancoLogo: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 435 73.5" {...props}>
    <Path
      fill="#000"
      d="M432.9 64.9a4.6 4.6 0 0 1 0 6.5 4.6 4.6 0 0 1-6.5 0 4.5 4.5 0 0 1-1.4-3.3 4.6 4.6 0 0 1 8-3.1m.5-.5a5.4 5.4 0 0 0-7.6 7.5 5.4 5.4 0 0 0 7.6-7.5"
    />
    <Path
      fill="#000"
      d="M430.3 68a3 3 0 0 1-1 0h-1v-2h1a2.7 2.7 0 0 1 1.2.1.9.9 0 0 1 .4.9 1 1 0 0 1-.6 1m-3 3.1h1v-2.3h.9a2.3 2.3 0 0 1 1.2.2 1.4 1.4 0 0 1 .5 1.3v.7a.2.2 0 0 1 0 .1h1a1 1 0 0 1 0-.4v-.9a1.7 1.7 0 0 0-.4-.9 1.5 1.5 0 0 0-1-.5 2.5 2.5 0 0 0 .9-.3 1.3 1.3 0 0 0 .5-1.2 1.5 1.5 0 0 0-.9-1.5 4.6 4.6 0 0 0-1.6-.2h-2Z"
    />
    <Path fill="#000" d="M36.8 72.6a35.8 35.8 0 0 1 0-71.7h361.4a35.8 35.8 0 0 1 0 71.7Z" />
    <Path
      fill="#000"
      d="M398.2 1.8a35 35 0 0 1 0 69.9H36.8a35 35 0 0 1 0-69.9Zm0-1.8H36.8a36.8 36.8 0 0 0 0 73.5h361.4a36.8 36.8 0 0 0 0-73.5"
    />
    <Path fill="#fff" d="M61.2 36.8a24.5 24.5 0 1 1-24.6-24.5 24.5 24.5 0 0 1 24.6 24.5" />
    <Path
      fill="#fff"
      d="M96.4 18.4h3.3v24q0 5.4 2.5 8t7.6 2.7q5 0 7.5-2.6t2.5-8.1v-24h3.4v23q0 7.4-3.4 11t-10 3.7q-6.8 0-10-3.7t-3.4-11Z"
    />
    <Path fill="#fff" d="M126.9 55.1V16.8l26 30.8V18.4h3.1v37.8l-26-30.8v29.7Z" />
    <Path fill="#fff" d="M169.2 55.1V21.5H159v-3.1h23.6v3.1h-10.1v33.6Z" />
    <Path
      fill="#fff"
      d="M220.4 36.8a18.8 18.8 0 0 1-1.5 7.4 19.1 19.1 0 0 1-4.2 6.3 20.1 20.1 0 0 1-21.5 4.2 19.7 19.7 0 0 1-10.7-10.5 18.7 18.7 0 0 1-1.4-7.4 19 19 0 0 1 1.4-7.4 19.3 19.3 0 0 1 4.3-6.3 18.7 18.7 0 0 1 6.3-4.1 21.2 21.2 0 0 1 15.3 0 19 19 0 0 1 6.3 4.1 19.3 19.3 0 0 1 4.3 6.3 19 19 0 0 1 1.4 7.4m-19.7 16.3A16 16 0 0 0 207 52a16.3 16.3 0 0 0 5.3-3.5 16.5 16.5 0 0 0 0-23.1 15.6 15.6 0 0 0-5.3-3.6 16.2 16.2 0 0 0-6.2-1.2 15.8 15.8 0 0 0-6.1 1.2 16.3 16.3 0 0 0-5.3 3.6 16.8 16.8 0 0 0-3.5 5.3 16.5 16.5 0 0 0 3.5 17.8 15.8 15.8 0 0 0 5.2 3.5 16 16 0 0 0 6.2 1.2"
    />
    <Path fill="#fff" d="M264.7 55.1V18.4h3.3V52h12.6v3.1Z" />
    <Path
      fill="#fff"
      d="M281.9 55.1 298 17l15.5 38.2h-3.7l-4.7-12.4h-15L285.3 55Zm9.4-15.3H304l-6.3-16Z"
    />
    <Path fill="#fff" d="M315 55.1V16.8l26 30.8V18.4h3.2v37.8l-26-30.8v29.7Z" />
    <Path
      fill="#fff"
      d="M380.3 25.1a19.7 19.7 0 0 0-5.6-3.5 16.3 16.3 0 0 0-6-1.1A15.6 15.6 0 0 0 357 25a16 16 0 0 0-4.5 11.8 18.6 18.6 0 0 0 1.1 6.5 14.6 14.6 0 0 0 3.3 5.2 15.1 15.1 0 0 0 5.1 3.4 16.1 16.1 0 0 0 6.1 1.1 18.2 18.2 0 0 0 6.2-1 20.3 20.3 0 0 0 5.7-3.2v3.9a24 24 0 0 1-5.7 2.5 21.1 21.1 0 0 1-6 1 20.7 20.7 0 0 1-7.7-1.5 18.3 18.3 0 0 1-6.2-4 17.7 17.7 0 0 1-4-6.2 21 21 0 0 1-1.5-7.7 20.6 20.6 0 0 1 1.5-7.7 17.5 17.5 0 0 1 4-6.1 18.4 18.4 0 0 1 6.3-4 20.7 20.7 0 0 1 7.6-1.4 21.1 21.1 0 0 1 6.5 1 17.4 17.4 0 0 1 5.4 2.8Z"
    />
    <Path
      fill="#fff"
      d="M422.6 36.8a18.8 18.8 0 0 1-1.5 7.4 19.1 19.1 0 0 1-4.3 6.3 20.1 20.1 0 0 1-21.5 4.2 19.8 19.8 0 0 1-10.6-10.5 18.7 18.7 0 0 1-1.5-7.4 19 19 0 0 1 1.5-7.4A19.3 19.3 0 0 1 389 23a18.7 18.7 0 0 1 6.3-4.1 21.2 21.2 0 0 1 15.2 0 19 19 0 0 1 6.3 4.1 19.3 19.3 0 0 1 4.3 6.3 19 19 0 0 1 1.5 7.4M402.9 53a16 16 0 0 0 6.1-1 16.3 16.3 0 0 0 5.2-3.5 16.5 16.5 0 0 0 0-23.1 15.6 15.6 0 0 0-5.2-3.6 16.2 16.2 0 0 0-6.2-1.2 15.8 15.8 0 0 0-6.2 1.2 16.3 16.3 0 0 0-5.2 3.6 16.7 16.7 0 0 0-3.5 5.3 16.5 16.5 0 0 0 3.5 17.8 15.8 15.8 0 0 0 5.2 3.5 16 16 0 0 0 6.2 1.2"
    />
    <Path
      fill="#fff"
      d="M245.3 55.1a33.2 33.2 0 0 0 7.7-.6 9.2 9.2 0 0 0 6.2-5.6 12.6 12.6 0 0 0 .8-4.6 8.6 8.6 0 0 0-2-5.8 8.8 8.8 0 0 0-5.6-2.8 7.2 7.2 0 0 0 4.2-2.8 8.1 8.1 0 0 0 1.6-5 10.1 10.1 0 0 0-1-4.4 9 9 0 0 0-2.8-3.3 8 8 0 0 0-3.1-1.4 32.4 32.4 0 0 0-6-.4h-9.5v1.4l3 1.5v33.8Zm-3.1-33.8h4.1q4.7 0 6.6 1.5t1.8 5.4q0 3.5-1.9 5t-6.5 1.5h-4.1Zm13.5 27a6.2 6.2 0 0 1-2 2.6 5.8 5.8 0 0 1-2.3 1 27.7 27.7 0 0 1-5 .3h-4.2V37.6h5a24.8 24.8 0 0 1 4 .2 7 7 0 0 1 2.2.8 5.6 5.6 0 0 1 2.2 2.5 9 9 0 0 1 .8 3.9 8.3 8.3 0 0 1-.7 3.4"
    />
    <Path
      fill="#fff"
      d="M91.9 23.7a8.1 8.1 0 0 0-2.7-3.5 9 9 0 0 0-3.2-1.4 28.7 28.7 0 0 0-5.9-.4h-9.7v1.4l3 1.5v33.8h3.4V38.6h3.8q6.4 0 9.3-2.4t3-7.7a12 12 0 0 0-1-4.9m-4.7 10.6q-2 1.5-8.2 1.5h-2.2V21.3H79a25 25 0 0 1 5.7.4 5.3 5.3 0 0 1 2.7 1.6 7.3 7.3 0 0 1 1.4 2.5 9 9 0 0 1 .5 3q0 3.8-2 5.4"
    />
  </Svg>
)

export default PuntoBlancoLogo
