import React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const SevenSevenLogo: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 232.3 17.8" {...props}>
    <Path
      d="M4.2 11.8c0 2 1.9 2.9 4.4 3 2.7.2 4.5-.6 4.5-2.2 0-1.4-1.1-2-3-2.2l-2.7-.2C4.1 9.9.8 9 .8 5c0-3.8 4.3-5 7.7-5 4 .1 7.8 1.3 8 5.7l-4.2.2c0-2-1.4-2.9-3.7-3C7 3 5 3.4 5 5c0 1.3 1.3 1.8 3.1 2l2.7.3c3.5.3 6.5 1.4 6.5 5.2 0 4-4.3 5.5-8.3 5.4-4.1 0-8.6-1-8.9-5.9z"
      fill="#000"
    />
    <Path d="M24.2.1h15.5v3H28.3v4.3h10v3h-10v4.3h11.6v3H24.2z" fill="#000" />
    <Path d="m53.7 11.7.5 1.1.5-1.2L59.8.3h4.5l-8.1 17.5h-4L44.1 0h4.5z" fill="#000" />
    <Path d="M70.6.1H86v3H74.6v4.3h10v3h-10v4.3h11.6v3H70.6z" fill="#000" />
    <Path d="m97.6 7.1-.7-1v11.6h-4V0h4.5l8.1 10.5 1 1.3V.1h4v17.6h-4.6z" fill="#000" />
    <Path
      d="M126 11.8c0 2 1.9 2.9 4.4 3 2.6.2 4.5-.6 4.5-2.2 0-1.4-1.1-2-3-2.2l-2.7-.2c-3.3-.3-6.6-1.2-6.6-5.2 0-3.8 4.3-5.1 7.7-5 4 .1 7.8 1.3 7.9 5.7l-4.1.2c0-2-1.4-2.9-3.7-3-1.7 0-3.6.4-3.7 2 0 1.3 1.3 1.8 3 2l2.8.3c3.5.3 6.5 1.4 6.5 5.2 0 4-4.3 5.5-8.3 5.4-4.1 0-8.6-1-9-6z"
      fill="#000"
    />
    <Path d="M146 .1h15.5v3h-11.4v4.3h10v3h-10v4.3h11.6v3H146z" fill="#000" />
    <Path d="m175.4 11.7.6 1.1.5-1.2 5-11.4h4.5l-8 17.5h-4L166 0h4.5z" fill="#000" />
    <Path d="M192.4.1h15.5v3h-11.5v4.3h10v3h-10v4.3H208v3h-15.6z" fill="#000" />
    <Path d="m219.4 7.1-.7-1v11.6h-4V0h4.5l8 10.5 1 1.3V.1h4v17.6h-4.5z" fill="#000" />
  </Svg>
)

export default SevenSevenLogo
